import { CloseOutlined } from "@ant-design/icons"
import { useMutation } from "@tanstack/react-query"
import { Button, Checkbox, InputNumber, message, Typography } from "antd"
import FormItemLabel from "antd/es/form/FormItemLabel"
import QueryBuilder from "components/QueryBuilder"
import { GroupSelect } from "components/Selects"
import { SelectEntity } from "components/Selects/types"
import { DebounceInput, Text } from "components/UI"
import { Flex } from "components/UI/Flex"
import api from "helpers/api"
import { useCallback, useState } from "react"
import { RuleGroupType } from "react-querybuilder"
import "react-querybuilder/dist/query-builder.scss"
import { Uuid } from "types"
import { ItemsGroupEntity, SeriesEnity } from "types/api"
import KitItemValueSelector from "./KitItemValueSelector"
import KitsItemFieldSelector from "./KitsItemFieldSelector"
import KitsItemsShowResult from "./KitsItemsShowResult"
import { KitData, KitItemData } from "./types"

const RenderItem = ({
    data,
    index,
    onDataChange,
}: {
    data: KitItemData
    index: number
    onDataChange: (index: number, key: keyof KitData, value: any) => void
}) => {
    return (
        <>
            <GroupSelect
                maxHeight={80}
                layout={"vertical"}
                activeGroup={data.group ?? undefined}
                label="Номенклатурная группа входящая в к-т"
                setActiveGroup={(v) => {
                    const value = v ?? null
                    onDataChange(index, "group", value)
                }}
                labelAdditionalContent={
                    <Flex.Row gap={10}>
                        <Checkbox
                            checked={data.isGroupRequired}
                            onChange={(e) =>
                                onDataChange(
                                    index,
                                    "isGroupRequired",
                                    e.target.checked
                                )
                            }
                        />
                        <Typography.Text>Для фото</Typography.Text>
                    </Flex.Row>
                }
            />

            <Flex.Col gap={10} justify="start">
                <Text.FormLabel>Количество в комплекте</Text.FormLabel>

                <Flex.Row gap={10} justify="start" align="center">
                    <InputNumber
                        style={{ width: 50 }}
                        controls={false}
                        value={data.counts.pc}
                        onChange={(e) =>
                            onDataChange(index, "counts", {
                                ...data.counts,
                                pc: e || 0,
                            })
                        }
                        onBeforeInput={(e) => {
                            e.currentTarget.value =
                                e.currentTarget.value.replace(/,/g, ".")
                        }}
                    />
                    <Text.FormLabel>шт.</Text.FormLabel>

                    <InputNumber
                        style={{ width: 50 }}
                        value={data.counts.kit}
                        onChange={(e) => {
                            onDataChange(index, "counts", {
                                ...data.counts,
                                kit: e || 0,
                            })
                        }}
                        controls={false}
                        onBeforeInput={(e) => {
                            e.currentTarget.value =
                                e.currentTarget.value.replace(/,/g, ".")
                        }}
                    />
                    <Text.FormLabel>компл.</Text.FormLabel>
                </Flex.Row>
            </Flex.Col>
        </>
    )
}

type GetKitEntityData = {
    createdAd: string
    name: string
    id: Uuid
    kitValues: Array<{
        id: Uuid
        itemGroup: ItemsGroupEntity
        kit: Uuid
        kitCount: number
        pcCount: number
        query: RuleGroupType
        isGroupRequired: boolean
    }>
    series: SeriesEnity
}

export default function KitsItems({
    currentSeries,
    currentModel,
    currentItemsGroup,
    currentProducer,
    onCreateItem,
    editItemId,
    cancelEdit,
    generateStandertKits,
}: {
    currentSeries: SelectEntity
    currentModel: SelectEntity | null
    currentItemsGroup: SelectEntity | null
    currentProducer: SelectEntity | null
    onCreateItem: () => void
    editItemId?: Uuid | null
    cancelEdit: () => void
    generateStandertKits: () => void
}) {
    const [messageApi, contextHolder] = message.useMessage()
    const [name, setName] = useState("")

    const mutateMethod = editItemId ? api.put.bind(api) : api.post.bind(api)
    const key = "updatable"

    const { mutateAsync: mutate } = useMutation(
        (data: KitItemData[]) =>
            mutateMethod(
                editItemId ? `v1/kits/edit/${editItemId}` : "v1/kits/create",
                {},
                {
                    series: currentSeries.value,
                    model: currentModel ? currentModel.value : undefined,
                    itemsGroup: currentItemsGroup?.value,
                    producer: currentProducer?.value,
                    payload: data,
                    name,
                }
            ),
        {
            onSuccess: () => {
                onCreateItem()
                messageApi.open({
                    key,
                    type: "success",
                    content: "Успех",
                    duration: 2,
                })
                if (!editItemId) {
                    setName("")
                }
            },
            onError: (error: any) => {
                messageApi.open({
                    key,
                    type: "error",
                    content: "Ошибка",
                    duration: 2,
                })
            },
        }
    )

    const handleSaveData = useCallback(async (v: KitItemData[]) => {
        mutate(v)
    }, [])

    return (
        <>
            {contextHolder}
            <Flex.Col fullWidth>
                {!!editItemId && (
                    <Flex.Row
                        gap={10}
                        fullWidth
                        justify="start"
                        styles={{ marginBottom: 20 }}
                    >
                        <Typography.Text>{`Редактирование комплекта - ${editItemId}`}</Typography.Text>
                        <Button
                            className="flex align-center justify-center"
                            style={{ padding: "0 10px", height: 35 }}
                            onClick={cancelEdit}
                        >
                            <CloseOutlined />
                        </Button>
                    </Flex.Row>
                )}
                <QueryBuilder
                    topContent={
                        <Flex.Row gap={10} styles={{ marginBottom: 20 }}>
                            <FormItemLabel
                                label={"Название шаблона"}
                                prefixCls="kits-name-label"
                                required
                            />
                            <DebounceInput
                                inputProps={{ size: "large" }}
                                initValue={name}
                                resolve={setName}
                            />
                        </Flex.Row>
                    }
                    editItemId={editItemId}
                    renderItem={RenderItem}
                    crudURL="v1/kits"
                    resolveGetEntitiesData={(data) =>
                        data.group?.value
                            ? {
                                  url: "v1/query-builder/kits/entities-list",
                                  params: { "items-group": data.group.value },
                              }
                            : undefined
                    }
                    noEntitiesText="Выберите группу"
                    resetQueryKeys={["group"]}
                    getEntityTransfromFunc={(data: GetKitEntityData) => {
                        setName(data.name)
                        return data.kitValues.map((el) => {
                            return {
                                group: {
                                    value: el.itemGroup?.code ??  el.itemGroup.id,
                                    label: el.itemGroup.name,
                                },
                                counts: {
                                    pc: el.pcCount,
                                    kit: el.kitCount,
                                },
                                query: el.query,
                                isGroupRequired: el.isGroupRequired,
                            } as KitItemData
                        })
                    }}
                    showDataByFilter={KitsItemsShowResult}
                    getControlElements={(data) => ({
                        valueEditor: KitItemValueSelector,
                        fieldSelector: KitsItemFieldSelector,
                    })}
                    handleSaveData={handleSaveData}
                    getDefaultData={() => ({
                        group: null,
                        counts: {
                            pc: 0,
                            kit: 0,
                        },
                        isGroupRequired: false,
                    })}
                    disableSave={!name}
                    bottomAdditionButtons={[
                        <Button
                            key={"create-kit"}
                            type="default"
                            disabled={!editItemId}
                            onClick={generateStandertKits}
                        >
                            Собрать комплекты
                        </Button>,
                    ]}
                />
            </Flex.Col>
        </>
    )
}
