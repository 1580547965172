import { Checkbox, Row, Spin } from "antd";
import { DebounceInput } from "components/UI";
import { Flex } from "components/UI/Flex";
import { UTable } from "components/UTable";
import React, { useMemo, useRef } from "react";
import { Uuid } from "types";

interface ItemsGroupRowsChooserRow {
  id: Uuid;
  label: string;
  alt?: string;
  accounting?: string;
}
interface ItemsGroupRowsChooserProps {
  rows: ItemsGroupRowsChooserRow[];
  onChangeAltName: (row: Record<Uuid, string>) => void;
  onChangeAccountingName: (row: Record<Uuid, string>) => void;
  loading?: boolean;
  unpublishedValues: Uuid[];
  unpublishAttribute: (code: Uuid) => void;
  unpublishLoading?: Uuid;
  setUnpublishLoading: (value: Uuid) => void;
  refetchAltNames: () => void,
  refetchAccountingNames: () => void
}

type TableData = {
  published: boolean;
} & ItemsGroupRowsChooserRow;

function PublishCheckbox({
  record,
  onChangeRow,
  unpublishLoading,
}: {
  record: TableData;
  onChangeRow: (v: Uuid) => void;
  unpublishLoading?: Uuid;
}) {
  const loading = unpublishLoading === record.id;

  return (
    <Row align={"middle"} justify={"center"}>
      {loading && <Spin />}
      {!loading && (
        <Checkbox
          checked={record.published}
          onChange={() => onChangeRow(record.id)}
        />
      )}
    </Row>
  );
}

export const ProducerAttributesValuesChooser: React.FC<
  ItemsGroupRowsChooserProps
> = (props) => {
  const {
    unpublishedValues,
    unpublishAttribute,
    unpublishLoading,
    setUnpublishLoading,
    loading,
    refetchAltNames,
    refetchAccountingNames
  } = props;

  const onChangeRow = (code: Uuid) => {
    setUnpublishLoading(code);
    return unpublishAttribute(code);
  };

  const filtredRows: TableData[] = useMemo(() => {
    const rows = props.rows || [];
    return rows
      .map((el) => {
        return {
          ...el,
          published: !unpublishedValues.includes(el.id),
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [props.rows, unpublishedValues]);

  const shouldRefetch = useRef(false)

  return (
    <Flex.Col fullWidth gap={10}>
      <Spin spinning={loading}>
        <UTable
          data={filtredRows}
          disableListVirtualization
          search
          searchCondition={(row, search) =>
            row.label.toLowerCase().includes(search.toLowerCase())
          }
          onSearch={() => {
            if(shouldRefetch.current) {
              refetchAltNames();
              refetchAccountingNames();
              shouldRefetch.current = false
            }
          }}
          columns={[
            {
              columnName: "ID",
              width: 15,
              render: (row) => row.id,
            },
            {
              columnName: "Значение",
              width: 25,
              render: (row) => row.label,
            },
            {
              columnName: "Альтернатива",
              width: 25,
              render: (item) => (
                <DebounceInput
                  initValue={item?.alt ?? ""}
                  key={item.id}
                  delay={400}
                  inputProps={{
                    bordered: false,
                    onBlur: () => shouldRefetch.current = true
                  }}
                  resolve={(val) =>
                    props.onChangeAltName({
                      [item.id]: val,
                    })
                  }
                />
              ),
            },
            {
              columnName: "Бух. название",
              width: 25,
              render: (item) => (
                <DebounceInput
                  initValue={item?.accounting ?? ""}
                  key={item.id}
                  delay={400}
                  inputProps={{
                    bordered: false,
                    onBlur: () => shouldRefetch.current = true
                  }}
                  resolve={(val) =>
                    props.onChangeAccountingName({
                      [item.id]: val,
                    })
                  }
                  
                />
              ),
            },
            {
              columnName: "Опубликовано",
              render: (item) => (
                <PublishCheckbox
                  record={item}
                  onChangeRow={onChangeRow}
                  unpublishLoading={unpublishLoading}
                />
              ),
            },
          ]}
        />
      </Spin>
    </Flex.Col>
  );
};
