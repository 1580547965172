import { useQuery } from "@tanstack/react-query"
import { Form, Input, InputNumber } from "antd"
import { FormProps, useForm } from "antd/es/form/Form"
import { Layout } from "components/layouts"
import FieldSelector from "components/QueryBuilder/FieldSelector"
import QueryBuilderResult from "components/QueryBuilder/QueryBuilderResult"
import QueryFormField from "components/QueryBuilder/QueryFormFIeld"
import ValuesAsyncSelector from "components/QueryBuilder/ValueAsyncSelector"
import { RegionFormSelect } from "components/Selects/newSelects/RegionFormSelect"
import { Flex } from "components/UI/Flex"
import api from "helpers/api"
import { formLayout } from "helpers/formLayout"
import withMessage, { WithShowMessage } from "hocs/withMessage"
import { usePageId } from "hooks/usePageId"
import { useReturn } from "hooks/useReturn"
import { useSaveOrAcceptProps } from "hooks/useSaveOrAccept"
import { useCallback, useMemo, useRef, useState } from "react"
import { RuleGroupType } from "react-querybuilder"
import { useHistory, useParams } from "react-router-dom"
import { InputOptionType, Uuid } from "types"
import { Country } from "types/api"
import {
    additionalFieldsSettings,
    AdditionalFieldType,
} from "./additionalFields"

type CreateOrEditFilterData = {
    parentGroup: Uuid
    name: string
    numericCode: number
    payload: {
        query: RuleGroupType<any>
        id?: Uuid
    }
    scope?: string | string[]
}

type GetFilterDataResponse = {
    filter: {
        id: Uuid
        name: string
        numericCode: number
        query?: {
            id: Uuid
            query?: RuleGroupType<any>
        }
        ignoreModel?: boolean
        scope?: Country[]
    }
}

type NomeclatureFiltersProps = WithShowMessage & {
    slug: string
    filterType: "availibiavailability" | "analogue"
    noRegionSelect?: true
    noNumericCode?: true
    additionalFields?: AdditionalFieldType[]
}

const getAdditionalData = (
    response: GetFilterDataResponse,
    additionalFields?: AdditionalFieldType[]
) => {
    const additionalProps = {} as Record<AdditionalFieldType, any>
    if (!additionalFields) return additionalProps
    additionalFields.forEach((key) => {
        additionalProps[key] =
            response.filter[key] ?? additionalFieldsSettings[key].defaultValue
    })
    return additionalProps
}

function NomeclatureFilters({
    showMessage,
    slug,
    filterType,
    noRegionSelect,
    noNumericCode,
    additionalFields,
}: NomeclatureFiltersProps) {
    const [selectedGroup, setSelectedGroup] = useState<null | InputOptionType>(
        null
    )
    const [query, setQuery] = useState<RuleGroupType<any>>()
    const { isLoading, withLoading } = useSaveOrAcceptProps()
    const params = useParams()
    const history = useHistory()
    const { id: itemId } = usePageId()
    const [form] = useForm<
        {
            parentGroup: InputOptionType
            name: string
            numericCode?: number
            scope: string | string[]
            id?: Uuid
            query?: RuleGroupType<any>
        } & Record<Partial<AdditionalFieldType>, any>
    >()
    const redirectRef = useRef<boolean>(true)
    const [sumbitLoading, setSubmitLoading] = useState(false)

    const { returnLink } = useReturn()

    const showRegions = !noRegionSelect
    const showNumericCode = !noNumericCode

    useQuery<GetFilterDataResponse>({
        queryKey: [itemId, slug],
        enabled: !!itemId,
        queryFn: () =>
            api.getTyped<GetFilterDataResponse>(`v1/${slug}/${itemId}`),
        onSuccess: (data) => {
            const { filter } = data
            form.setFieldsValue({
                name: filter.name,
                numericCode: filter.numericCode,
                query: filter.query,
                scope: filter.scope
                    ? filter.scope.map((el) => el.id)
                    : undefined,
                ...getAdditionalData(data, additionalFields),
            })
            setQuery(
                filter?.query?.query
                    ? {
                          ...filter.query?.query,
                          rules: filter.query.query.rules ?? [],
                      }
                    : undefined
            )
        },
    })

    const mutations = {
        create: (requestData: CreateOrEditFilterData) =>
            api.post(`v1/${slug}/create`, {}, requestData),
        update: (requestData: CreateOrEditFilterData) =>
            api.put(`v1/${slug}/update/${itemId}`, {}, requestData),
    }

    const onFinish: FormProps["onFinish"] = (values) => {
        if (!query) return
        setSubmitLoading(true)
        const requestData: CreateOrEditFilterData = {
            parentGroup: selectedGroup?.value || "",
            payload: {
                query: query,
                id: itemId,
            },
            ...values,
            ...(additionalFields?.includes("ignoreModel") && !values?.ignoreModel 
            ? { ignoreModel: false } 
            : {}),
        }

        const mutatationKey: keyof typeof mutations = itemId
            ? "update"
            : "create"
        const mutate = mutations[mutatationKey]
        mutate(requestData)
            .then(() => {
                if (redirectRef.current) {
                    setTimeout(() => {
                        history.push(`${returnLink}`)
                    }, 3000)
                }
                showMessage({
                    type: "success",
                    content: "Успешно",
                })
            })
            .catch((error) => {
                showMessage({
                    type: "error",
                    content: error.message,
                })
            })
            .finally(() => {
                setSubmitLoading(false)
            })
    }

    const { pageTitle, queryBuilderUrl } = useMemo(() => {
        const action = itemId ? "Редактировать " : "Создать "
        switch (filterType) {
            case "analogue":
                return {
                    pageTitle: action + "фильтр аналогов номенклатуры",
                    queryBuilderUrl: "nomenclature/analogs",
                }
            case "availibiavailability":
                return {
                    pageTitle: action + "фильтр доступности номенклатуры",
                    queryBuilderUrl: "price-nomenclature-filter",
                }
            default:
                return { pageTitle: "", queryBuilderUrl: "" }
        }
    }, [itemId, filterType])

    const onSave = useCallback((redirect: boolean) => {
        redirectRef.current = redirect
        form.submit()
    }, [])

    return (
        <Layout.Detail
            pageTitle={pageTitle}
            onSaveProps={{
                onSave: () => onSave(true),
                disabled: !query,
                isLoading: sumbitLoading,
            }}
            onAcceptProps={{
                onAccept: () => onSave(false),
                disabled: !query,
            }}
        >
            <Flex.Col gap={10} fullWidth>
                <Form
                    form={form}
                    className="fw"
                    {...formLayout}
                    onFinish={onFinish}
                >
                    <Flex.Col gap={10} fullWidth>
                        <Form.Item
                            label="Название"
                            className="fw"
                            name={"name"}
                        >
                            <Input size="large" className="fw" />
                        </Form.Item>

                        {showNumericCode && (
                            <Form.Item
                                label="Код"
                                className="fw"
                                name={"numericCode"}
                            >
                                <InputNumber size="large" className="fw" />
                            </Form.Item>
                        )}

                        {showRegions && (
                            <RegionFormSelect
                                className="fw"
                                isMultiple
                                name="scope"
                            />
                        )}

                        {additionalFields &&
                            additionalFields.map((key) => {
                                const Component =
                                    additionalFieldsSettings[key].Component
                                const { label, name } =
                                    additionalFieldsSettings[key]
                                return (
                                    <Component
                                        label={label}
                                        name={name}
                                        key={key}
                                        form={form}
                                    />
                                )
                            })}

                        <Form.Item
                            label="Фильтр"
                            name={"query"}
                            className="fw"
                            labelCol={{ span: 24 }}
                        >
                            <QueryFormField
                                query={query}
                                getEntitiesURL={`v1/query-builder/${queryBuilderUrl}/entities-list`}
                                controlElements={{
                                    valueEditor: (props) => (
                                        <ValuesAsyncSelector
                                            {...props}
                                            requestData={{
                                                attribute: props.field,
                                            }}
                                            optionUrl="v1/query-builder/price-nomenclature-filter/values-list"
                                        />
                                    ),
                                    fieldSelector: FieldSelector,
                                }}
                                onQueryChange={(q) => {
                                    setQuery({
                                        ...q,
                                        rules: q?.rules ?? [],
                                    })
                                }}
                                translations={{
                                    addGroup: { label: "Добавить группу" },
                                    addRule: { label: "Добавить правило" },
                                }}
                            />
                        </Form.Item>
                        <Flex.Row fullWidth gap={20}>
                            <QueryBuilderResult
                                dataUrl={`v1/query-builder/${queryBuilderUrl}/result`}
                                query={query}
                            />
                        </Flex.Row>
                    </Flex.Col>
                </Form>
            </Flex.Col>
        </Layout.Detail>
    )
}

export default withMessage(NomeclatureFilters)
